var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"list",staticClass:"song-list"},[_c('div',{staticClass:"song-list-controls mb-4 pb-1"},[_vm._m(0),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"form-group",staticStyle:{"flex":"1"}},[_c('label',[_vm._v("Sort By")]),_c('div',{staticClass:"pill-button-group"},[_c('button',{class:[
                            'pill-button',
                            ("pill-button--" + (_vm.titleOrder == null ? 'outline' : 'black'))
                        ],on:{"click":function($event){return _vm.sort('title')}}},[_vm._v(" Title "),(_vm.titleOrder === 'asc')?_c('span',{staticClass:"sortOrder asc"},[_vm._v("↑")]):_vm._e(),(_vm.titleOrder === 'desc')?_c('span',{staticClass:"sortOrder desc"},[_vm._v("↓")]):_vm._e(),(_vm.titleOrder === null)?_c('span',{staticClass:"sortOrder"},[_vm._v("↑↓")]):_vm._e()]),_c('button',{class:[
                            'pill-button',
                            ("pill-button--" + (_vm.artistOrder == null ? 'outline' : 'black'))
                        ],on:{"click":function($event){return _vm.sort('artist')}}},[_vm._v(" Artist "),(_vm.artistOrder === 'asc')?_c('span',{staticClass:"sortOrder asc"},[_vm._v("↑")]):_vm._e(),(_vm.artistOrder === 'desc')?_c('span',{staticClass:"sortOrder desc"},[_vm._v("↓")]):_vm._e(),(_vm.artistOrder === null)?_c('span',{staticClass:"sortOrder"},[_vm._v("↑↓")]):_vm._e()]),_c('button',{class:[
                            'pill-button',
                            ("pill-button--" + (_vm.statusOrder == null ? 'outline' : 'black'))
                        ],on:{"click":function($event){return _vm.sort('status')}}},[_vm._v(" Status "),(_vm.statusOrder === 'asc')?_c('span',{staticClass:"sortOrder asc"},[_vm._v("↑")]):_vm._e(),(_vm.statusOrder === 'desc')?_c('span',{staticClass:"sortOrder desc"},[_vm._v("↓")]):_vm._e(),(_vm.statusOrder === null)?_c('span',{staticClass:"sortOrder"},[_vm._v("↑↓")]):_vm._e()])])]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Filter songs by Status")]),_c('div',{staticClass:"pill-button-group"},[_c('button',{class:[
                            'pill-button',
                            ("pill-button--" + (_vm.currentFilter.status === 'draft' ? 'black' : 'outline'))
                        ],on:{"click":function($event){return _vm.toggleFilter('status', 'draft')}}},[_vm._v(" Draft ")]),_c('button',{class:[
                            'pill-button',
                            ("pill-button--" + (_vm.currentFilter.status === 'publish' ? 'black' : 'outline'))
                        ],on:{"click":function($event){return _vm.toggleFilter('status', 'publish')}}},[_vm._v(" Publish ")])])]),_c('div',{class:[
                    'song-list-controls__action',
                    'text-right',
                    { 'is-hidden': !_vm.selectedSongs || _vm.selectedSongs.length === 0}
                ],staticStyle:{"flex":"1"}},[_c('div',{staticClass:"form-group"},[_c('label',{domProps:{"innerHTML":_vm._s(_vm.selectedSongs.length === 0 ? '&nbsp' : ((_vm.selectedSongs.length) + " Track(s) Selected"))}}),_c('div',{staticClass:"dropdown dropleft text-right"},[_c('button',{staticClass:"pill-button pill-button--outline dropdown-toggle",attrs:{"type":"button","id":"songActionsDrop","data-toggle":"dropdown","data-flip":"true","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" Actions ")]),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"songActionsDrop"}},[_c('a',{staticClass:"dropdown-item clickable",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.songActions('publish')}}},[_vm._v("Set status to \"Publish\"")]),_c('a',{staticClass:"dropdown-item clickable",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.songActions('draft')}}},[_vm._v("Set status to \"Draft\"")])])])])])])]),(_vm.loading)?_c('div',{staticClass:"text-center"},[_vm._v(" Loading songs... ")]):_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table actions-enabled"},[_vm._m(1),_c('tbody',{staticClass:"list"},_vm._l((_vm.songs),function(song){return _c('tr',{key:song.id,class:[
                        'song',
                        {
                            'is-selected': _vm.selectedSongs.includes(song.id)
                        }
                    ],on:{"click":function () { return _vm.onSongRowClick(song); }}},[_c('td',{staticClass:"td-select"},[_c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedSongs),expression:"selectedSongs"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":'select-song-'+song.id},domProps:{"value":song.id,"checked":Array.isArray(_vm.selectedSongs)?_vm._i(_vm.selectedSongs,song.id)>-1:(_vm.selectedSongs)},on:{"change":function($event){var $$a=_vm.selectedSongs,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=song.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedSongs=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedSongs=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedSongs=$$c}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":'select-song-'+song.id}},[_c('span',{staticClass:"sr-only"},[_vm._v("Toggle song selection")])])])]),_c('td',{staticClass:"title"},[_vm._v(_vm._s(song.title))]),_c('td',{staticClass:"artist"},[_vm._v(_vm._s(song.artist))]),_c('td',{staticClass:"status"},[_vm._v(_vm._s(song.status))]),_c('td',{staticClass:"edit"},[_c('a',{staticClass:"pill-button",attrs:{"href":("/admin/songs/" + (song.id)),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();true /* prevent triggering row click */}}},[_vm._v(" Edit ")])])])}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-box"},[_c('label',[_vm._v("Fuzzy Search")]),_c('input',{staticClass:"form-control search-field",attrs:{"type":"text","placeholder":"Type to search...","aria-label":"Search"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("Title")]),_c('th',[_vm._v("Artist")]),_c('th',[_vm._v("Status")]),_c('th')])])}]

export { render, staticRenderFns }