<template>
    <div class="song-list" ref="list">
        <div class="song-list-controls mb-4 pb-1">
            <div class="search-box">
                <label>Fuzzy Search</label>
                <input type="text" class="form-control search-field" placeholder="Type to search..." aria-label="Search">
            </div>

            <div class="d-flex justify-content-between">
                <div class="form-group" style="flex:1">
                    <label>Sort By</label>
                    <div class="pill-button-group">
                        <button
                            @click="sort('title')"
                            :class="[
                                'pill-button',
                                `pill-button--${titleOrder == null ? 'outline' : 'black'}`
                            ]"
                        >
                            Title
                            <span v-if="titleOrder === 'asc'" class="sortOrder asc">&#x2191;</span>
                            <span v-if="titleOrder === 'desc'" class="sortOrder desc">&#x2193;</span>
                            <span v-if="titleOrder === null" class="sortOrder">&#x2191;&#x2193;</span>
                        </button>
                        <button
                            @click="sort('artist')"
                            :class="[
                                'pill-button',
                                `pill-button--${artistOrder == null ? 'outline' : 'black'}`
                            ]"
                        >
                            Artist
                            <span v-if="artistOrder === 'asc'" class="sortOrder asc">&#x2191;</span>
                            <span v-if="artistOrder === 'desc'" class="sortOrder desc">&#x2193;</span>
                            <span v-if="artistOrder === null" class="sortOrder">&#x2191;&#x2193;</span>
                        </button>
                        <button
                            @click="sort('status')"
                            :class="[
                                'pill-button',
                                `pill-button--${statusOrder == null ? 'outline' : 'black'}`
                            ]"
                        >
                            Status
                            <span v-if="statusOrder === 'asc'" class="sortOrder asc">&#x2191;</span>
                            <span v-if="statusOrder === 'desc'" class="sortOrder desc">&#x2193;</span>
                            <span v-if="statusOrder === null" class="sortOrder">&#x2191;&#x2193;</span>
                        </button>
                    </div>
                </div>

                <div class="form-group">
                    <label>Filter songs by Status</label>
                    <div class="pill-button-group">
                        <button
                            @click="toggleFilter('status', 'draft')"
                            :class="[
                                'pill-button',
                                `pill-button--${currentFilter.status === 'draft' ? 'black' : 'outline'}`
                            ]"
                        >
                            Draft
                        </button>
                        <button
                            @click="toggleFilter('status', 'publish')"
                            :class="[
                                'pill-button',
                                `pill-button--${currentFilter.status === 'publish' ? 'black' : 'outline'}`
                            ]"
                        >
                            Publish
                        </button>
                    </div>
                </div>

                <div
                    :class="[
                        'song-list-controls__action',
                        'text-right',
                        { 'is-hidden': !selectedSongs || selectedSongs.length === 0}
                    ]"
                    style="flex:1"
                >
                    <div class="form-group">
                        <label
                            v-html="selectedSongs.length === 0 ? '&nbsp' : `${selectedSongs.length} Track(s) Selected`"
                        />
                        <div class="dropdown dropleft text-right">
                            <button
                                class="pill-button pill-button--outline dropdown-toggle"
                                type="button"
                                id="songActionsDrop"
                                data-toggle="dropdown"
                                data-flip="true"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                Actions
                            </button>
                            <div class="dropdown-menu" aria-labelledby="songActionsDrop">
                                <a href="#" class="dropdown-item clickable" @click.prevent="songActions('publish')">Set status to "Publish"</a>
                                <a href="#" class="dropdown-item clickable" @click.prevent="songActions('draft')">Set status to "Draft"</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="loading" class="text-center">
            Loading songs...
        </div>
        <div v-else class="table-responsive">
            <table class="table actions-enabled">
                <thead>
                    <tr>
                      <th></th>
                      <th>Title</th>
                      <th>Artist</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                </thead>
                <tbody class="list">
                    <tr
                        v-for="song in songs"
                        :key="song.id"
                        :class="[
                            'song',
                            {
                                'is-selected': selectedSongs.includes(song.id)
                            }
                        ]"
                        @click="() => onSongRowClick(song)"
                    >
                        <td class="td-select">
                            <div class="custom-control custom-checkbox">
                                <input
                                    :value="song.id"
                                    v-model="selectedSongs"
                                    type="checkbox"
                                    class="custom-control-input"
                                    :id="'select-song-'+song.id"
                                >
                                <label class="custom-control-label" :for="'select-song-'+song.id">
                                    <span class="sr-only">Toggle song selection</span>
                                </label>
                            </div>
                        </td>
                        <td class="title">{{ song.title }}</td>
                        <td class="artist">{{ song.artist }}</td>
                        <td class="status">{{ song.status }}</td>
                        <td class="edit">
                            <a
                                :href="`/admin/songs/${song.id}`"
                                target="_blank"
                                class="pill-button"
                                @click.stop="true /* prevent triggering row click */"
                            >
                                Edit
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import List from 'list.js';

const LIST_OPTIONS = {
  valueNames: ['title', 'artist', 'status'],
  fuzzySearch: {
    searchClass: 'search-field'
  }
}

export default {
  name: 'SongList',
  data: () => ({
    listObject: null,
    titleOrder: null,
    artistOrder: null,
    statusOrder: null,
    selectedSongs: [],
    songs: [],
    loading: false,
    currentFilter: {
      status: null
    }
  }),
  mounted () {
    this.loading = true

    this.$http
      .get('/admin/songs')
      .then(({ data }) => {
        this.songs = data.songs
        this.loading = false

        this.$nextTick(() => {
          this.interval = setInterval(() => {
            if (this.$el.querySelectorAll('.song').length) {
              this.listObject = new List(this.$refs.list, LIST_OPTIONS)
              clearInterval(this.interval)
            }
          }, 100)
        })
      })
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    songActions (action) {
      this.$http
        .post('/admin/songs/actions', {
          song_action: action,
          songs: this.selectedSongs
        })
      .then(re => {
        if (re.data.status == 1) {
          re.data.songs.forEach(song => {
            const index = this.songs.findIndex(({ id }) => id == song.id)

            if (index > -1) {
              Vue.set(this.songs, index, song)
            }
          })
          this.$vs.notification({
            position: 'top-center',
            title: 'Success!',
            text: 'Songs Updated'
          })
        } else {
          this.$vs.notification({
            position: 'top-center',
            title: 'Failed!',
            text: 'Something went wrong.'
          })
        }
      })
    },
    toggleFilter (key, filterVal) {
      const f = this.currentFilter[key] == filterVal ? null : filterVal

      this.$set(this.currentFilter, key, f)

      if (f == null) {
        this.listObject.filter(() => true)
      } else {
        this.listObject.filter((item) => {
          // Logic may change depending on how we display the song data in the list. Currently assumes a structure of "Key: value"
          // let value = item.values()[key].split(/^[^:]+: /)[1];
          const value = item.values()[key] // .split(/^[^:]+: /)[1];

          return value == f
        })
      }
    },
    sort (sortBy) {
      let order = ''

      switch (sortBy) {
        case 'title':
          this.titleOrder = this.titleOrder == 'desc' || this.titleOrder == null ? 'asc' : 'desc'
          this.statusOrder = null
          this.artistOrder = null
          order = this.titleOrder
          break
        case 'status':
          this.statusOrder = this.statusOrder == 'desc' || this.statusOrder == null ? 'asc' : 'desc'
          this.titleOrder = null
          this.artistOrder = null
          order = this.statusOrder
          break
        case 'artist':
          this.artistOrder = this.artistOrder == 'desc' || this.artistOrder == null ? 'asc' : 'desc'
          this.statusOrder = null
          this.titleOrder = null
          order = this.artistOrder
          break
      }

      this.listObject.sort(sortBy, { order: order })
    },
    onSongRowClick ({ id }) {
      const index = this.selectedSongs.findIndex(songId => songId == id)

      if (index == -1) {
        this.selectedSongs.push(id)
      } else {
        this.selectedSongs.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.song-list-controls {
    position: sticky;
    z-index: 10;
    top: 0;
    padding-top: 50px;
    background-color: white;
    border-bottom: 2px solid black;
}

.search-box {
    padding: 14px 18px 20px;
    background-color: rgb(239, 239, 239);
    margin-bottom: 22px;
}

.table {
    td, th {
        vertical-align: middle;
    }

    tr {
        td:nth-child(4) {
            text-transform: capitalize;
        }

        td:last-child {
            text-align: right;
        }
    }

    .td-select {
        width: 55px;

        .custom-checkbox {
            pointer-events: none;
        }
    }

    td.title {
        font-weight: 500;
    }
}

.song {
    .actions-enabled & {
        cursor: pointer;
    }

    &.is-selected {
        td {
            background-color: transparentize(#40FA19, 0.75);
        }
    }
}

.song-list-controls__action {
    transition: opacity 300ms ease-out;

    &.is-hidden {
        opacity: 0;
        pointer-events: none;
    }
}
</style>
